import React from "react"
import PricingCard from "./pricing-card"

const PricingPlans = ({ data }) => {
  const items = [
    {
      title: '$200 Plan FEE',
      description: '7 days free Forex Analytix Premium Account Access',
      monthlyPrice: 5000,
      features: [
        {title: "Daily Max Drawdown 5%, based off the prior day's closing balance"},
        {title: "Max Account Trailing Drawdown is -5% and it's based off your high water mark. It trails up as you increase the account balance, and then locks at your starting balance. Account locks at starting balance at payout."},
        {title: "<b>Need to make at least one trade every 30 days, otherwise the account is breached.</b>"},
        {title: "Account Gains Split: Keep 80% (or 90% if you have purchased this extra option) or compound your account, first withdraw anytime you wish."},
      ]
    },
    {
      title: '$400 Plan FEE',
      description: '14 days free Forex Analytix Premium Account Access',
      monthlyPrice: 10000,
      features: [
        {title: "Daily Max Drawdown 5%, based off the prior day's closing balance."},
        {title: "Max Account Trailing Drawdown is -5% and it's based off your high water mark. It trails up as you increase the account balance, and then locks at your starting balance. Account locks at starting balance at payout."},
        {title: "<b>Need to make at least one trade every 30 days, otherwise the account is breached.</b>" },
        {title: "Account Gains Split: Keep 80% (or 90% if you have purchased this extra option) or compound your account, first withdraw anytime you wish."},
      ]
    },
    {
      title: '$1,125 Plan FEE',
      description: '30 days free Forex Analytix Premium Account Access',
      monthlyPrice: 25000,
      features: [
        {title: "Daily Max Drawdown 5%, based off the prior day's closing balance"},
        {title: "Max Account Trailing Drawdown is -5% and it's based off your high water mark. It trails up as you increase the account balance, and then locks at your starting balance. Account locks at starting balance at payout." },
        {title: "<b>Need to make at least one trade every 30 days, otherwise the account is breached.</b>" },
        {title: "Account Gains Split: Keep 80% (or 90% if you have purchased this extra option) or compound your account, first withdraw anytime you wish." },
      ]
    },
    {
      title: '$2,500 Plan FEE',
      description: '60 days free Forex Analytix Premium Account Access',
      monthlyPrice: 50000,
      features: [
        {title: "Daily Max Drawdown 5%, based off the prior day's closing balance" },
        {title: "Max Account Trailing Drawdown is -5% and it's based off your high water mark. It trails up as you increase the account balance, and then locks at your starting balance. Account locks at starting balance at payout." },
        {title: "<b>Need to make at least one trade every 30 days, otherwise the account is breached.</b>" },
        {title: "Account Gains Split: Keep 80% (or 90% if you have purchased this extra option) or compound your account, first withdraw anytime you wish." },
      ]
    },
    {
      title: '$5,000 Plan FEE',
      description: '120 days free Forex Analytix Premium Account Access',
      monthlyPrice: 100000,
      features: [
        {title: "Daily Max Drawdown 5%, based off the prior day's closing balance" },
        {title: "Max Account Trailing Drawdown is -5% and it's based off your high water mark. It trails up as you increase the account balance, and then locks at your starting balance. Account locks at starting balance at payout." },
        {title: "<b>Need to make at least one trade every 30 days, otherwise the account is breached.</b>" },
        {title: "Account Gains Split: Keep 80% (or 90% if you have purchased this extra option) or compound your account, first withdraw anytime you wish." },
      ]
    },
  ]

  return (
    <div className="bg-black" id="pricing-plans">

        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-6 lg:max-w-none">

                <h2 className="text-lg font-semibold leading-6 tracking-wider text-gray-100 uppercase">
                  IMMEDIATE LIVE ACCOUNT TRADING ACCOUNTS
                </h2>

                <p className="text-3xl font-extrabold text-green sm:text-4xl lg:text-5xl">
                  Which Account is Best for You?
                </p>

                <p className="text-xl text-gray-100">
                  {/*sectionHeading?.text*/}
                  Choose a starting balance between <span className="text-indigo-400">$5,000 USD </span>and <span className="text-indigo-400">$100,000 USD</span>.
                </p>

            </div>
          </div>
        </div>

      <div className="pb-12 mt-8 bg-black sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        {items?.length > 0 && (
          <div className="relative">
            <div className="absolute inset-0 bg-black h-3/4"></div>
            <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                {items.map((item, index) => (
                  <PricingCard key={index} index={index} data={item} />
                ))}
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default PricingPlans
