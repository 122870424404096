import React from "react"
import img from "../images/our-approach.jpg"

const OurApproach = ({landingPage}) => {
  return (
    <div
      className="relative py-4 overflow-x-hidden bg-white sm:py-0"
      id="success"
    >
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div
          className="relative sm:py-16 lg:py-0">

          <div className="relative max-w-md px-0 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-0">
            <div className=" pb-0 pt-10">
                <img
                  className="inset-0 w-full h-full"
                  src={img}
                  alt=""
                />
            </div>
          </div>
        </div>

        <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl font-extrabold tracking-tight text-black sm:text-4xl">
              What is 1-Step-Funding?
            </h2>
            <div className="mt-6 text-black">
              Prove that you have the right trading skills on a <b>DEMO</b> account and get funded immediately.
              <br />
              <br />
              How do you prove it? By generating a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trading at least once every 30 days.</b>
              <br />
              <br />
              Furthermore, there is an extra option that you can purchase which allows you to carry trades over weekends – if you haven’t purchased this option any open trades will be automatically closed on Friday ahead of the weekend.
              <br/>
              <br/>
              <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
              <br/>
              <br/>
              Once you have successfully passed the practical assessment, we will invest in you and support you on your prop trading journey. We will fund your live account which you will then be able to trade as you see fit, while still abiding by the drawdown rules. As long as you stay within the drawdown criteria, <b>you will keep 75% of the gains, or 90% if you have purchased this extra option.</b>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default OurApproach
