import * as React from "react"
import Header from "./header1"
import Features from "./features"
import OurCommunity from "./our-community"
import OurTechnology from "./our-technology"
import OurApproach from "./our-approach-one-step-funding"
import Succeed from "./succeed"
import PricingPlans from "./pricing-plans"
import PresentationVideo from "./presentation-video"
import Faqs from "./faqs"
import Testimonials from "./testimonials"
import Callout from "./callout"
import Footer from "./footer"
import Privacy from "./privacy"
import ClientTerms from "./client-terms"
import Menu from "./menu2"
import Seo from "./seo"
// import Offer from "./holidays-offer"

import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useQueryParam, StringParam } from "use-query-params"

const Layout = ({ landingPage }) => {

  const location = useLocation();

  // console.log('>>>> location', location);
  // console.log('>>>> landingPage', landingPage);

  // get ref code from URL
  // const [ref] = useQueryParam("ref", StringParam);
  // const [affiliateid] = useQueryParam("affiliateid", StringParam);

  // // while this will work on dev mode without the if, in prod build it will fail due to gatsby optimazations
  // if (typeof window !== 'undefined') {

  //   const ref_code = localStorage.getItem("ref_code");
  //   if (ref)
  //     localStorage.setItem("ref_code", ref);

  //   const affiliate_id = localStorage.getItem("affiliate_id");
  //   if (affiliateid)
  //     localStorage.setItem("affiliate_id", affiliateid);
  //   // console.log('>>>>>>> ref / affiliateid', ref, affiliateid)
  // }

  return (
    <>
      <CookieConsent
        location="bottom"
        cookieName="gatsby-gdpr-google-tagmanager"
        style={{ background: 'rgb(51,51,51)', padding: '15px' }}
        buttonText="Accept"
        buttonStyle={{
          backgroundColor: '#FFF',
          color: '#000',
          fontSize: '13px',
          borderRadius: '5px',
        }}
        enableDeclineButton
        declineButtonText="Decline"
        declineButtonStyle={{
          backgroundColor: '#555',
          color: '#efefef',
          fontSize: '13px',
          borderRadius: '5px',
        }}
        onAccept={() => {
        initializeAndTrack(location)
      }}
      >
      This website uses cookies to enhance the user experience. Your privacy is important to us, and we will never sell your data. <br/><a style={{textDecoration: 'underline'}} href="/privacy">Privacy Policy</a>
      </CookieConsent>

      <Menu />
      {location.pathname.startsWith("/privacy") ? (
        <main>
          <Privacy />
        </main>
        ) : location.pathname.startsWith("/client-terms") ? (
        <main>
          <ClientTerms />
        </main> ) : (
        <>
          <Header />
          <Seo landingPage={landingPage} />
            <main>
              <OurApproach />
              <PricingPlans />
              <Faqs />
              <Callout />
            </main >
        </>
      )}
      <Footer />
    </>
  )
}

export default Layout
