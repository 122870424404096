import React from "react"

const OurApproachContent = (page, reversed) => {

  if (page == 'usa-new-york') {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the 1-Step Assessment plans, you will need to qualify on a Demo account first, while on the Immediate Live Trading Accounts you will trade a live funded account straight away.
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    After you get funded, you will continue to receive ongoing support from <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u>. You will also be able to join as a guest on our internationally acclaimed FACE (Forex Analytix Community Experience) daily shows as one of our speakers and tell your success story. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We base our recruitment on merit, not location, education, age, or demographics. We want to give everyone an equal chance at becoming a trader.
    <br/>
    <br/>
    When you are in one of our <b>funded trader program</b>, you will have access to advanced performance analytics in an intuitive user dashboard. With this information readily available you will be much more efficient and make the best trading decisions.
    `)
  }
  else if (page == 'uk-london') {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the 1-Step Assessment plans, you will need to qualify on a Demo account first, while on the Immediate Live Trading Accounts you will trade a live funded account straight away.
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    After you get funded, you are not left to fend for yourself. <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> will keep supporting you and you will also participate in our prominent FACE (Forex Analytix Community Experience) shows where you will get to speak as an expert in your field. We are by your side, to make sure you keep exceeding your expectations. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Our recruitment process is completely unbiased and based solely on merit. Your location, education level, your age or employment history are completely irrelevant to us. Our goal is to give everyone a chance to become successful at trading.
    <br/>
    <br/>
    When you're in one of our <b>funded trader program</b>, you'll have access to advanced analytics via a user-friendly dashboard that provides you all the information you need to make better trading decisions that are backed by data and insight.
    `)
  }
  else if (page == 'canada-toronto') {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the 1-Step Assessment plans, you will need to qualify on a Demo account first, while on the Immediate Live Trading Accounts you will trade a live funded account straight away.
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    Once you're funded, you will have access to our exclusive <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> community. You'll also have the opportunity to join as a guest on our world-famous FACE (Forex Analytix Community Experience) daily shows and tell your success story. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We want to give an equal opportunity to everyone who wants to be a trader. We truly believe that a person's abilities and hard work, not their social or cultural background, is what matters most in their effort to become a successful trader, thus we are open to anyone who wants to join us.
    <br/>
    <br/>
    When you join one of our <b>funded trader program</b>, you will be able to see advanced performance analytics in an easy-to-understand dashboard and utilize this information to make orderly trading decisions based on quantitative data.
    `)
  }
  else if (page == 'india-mumbai') {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the 1-Step Assessment plans, you will need to qualify on a Demo account first, while on the Immediate Live Trading Accounts you will trade a live funded account straight away.
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    We at <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> will continue to reinforce your development, after you get funded. You will also have the opportunity to take part in our exclusive FACE (Forex Analytix Community Experience) daily shows, where you can share your knowledge and experiences with other members of our community. We will always stand by you, to ensure you continue to advance in your prop training journey. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We are completely impartial and recruit people based on objective standards. Your sociocultural background is not relevant to us. Anyone who has the willpower and puts in the work is given an equal opportunity to join us as a <b>funded trader in India, Mumbai.</b>
    <br/>
    <br/>
    Throughout your trading journey, you will gain access to advanced performance analytics, presented to you in a clear an intuitive way. With all this information at your fingertips, you will become more efficient and perfect your trading skills.
    `)
  }
  else if (page == 'australia-sydney') {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the 1-Step Assessment plans, you will need to qualify on a Demo account first, while on the Immediate Live Trading Accounts you will trade a live funded account straight away.
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    At <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytics</a></u> we do not stop supporting you once you get funded. You may also join us on our world-famous FACE (Forex Analytix Community Experience) shows and share your insights and your story. We are always next to you, to guarantee you pursue your goals and achieve success. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Throughout our recruitment process, we don’t consider you’re your location, education, or other demographics. Our process is 100% based on merit, because we want to provide an equal chance to everyone who wants to be successful at trading.
    <br/>
    <br/>
    When you join our <b>funded trader program in Australia, Sydney</b> you will gain access to advanced performance analytics, presented in an easy-to-understand way. With this information you will hone your trading skills and make more profitable decisions, based on valid data.
    `)
  }
  else {
    return !reversed ? (
    `
    Whether you choose an <b>Immediate Live Trading Account or a 1-Step Assessment</b>, you will need to adhere to a daily maximum drawdown level, as well as a maximum trailing drawdown level. You will also need to <b>trade at least once every 30 days.</b>
    <br/>
    <br/>
    <b>For the Immediate Live Trading Account, you will trade a live funded account straight away, while for the 1-Step Assessment plans, you will need to qualify on a Demo account first.</b>
    </b>
    <br/>
    <br/>
    <b>It’s as simple as that.</b> There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    For more information and details, please see the FAQs provided for each account type.
    ` ) : (
    `
    Once you are funded, you will continue to receive ongoing support from <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u>. You will also have the ability to join as a guest on our world-renowned FACE (Forex Analytix Community Experience) daily shows – as a speaker and success story. We are here to help ensure your continued success in the markets. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Our recruitment process is 100% based on merit. We don’t care where you live, what your education or employment history is, your age or other demographics. We want to give equal opportunity to everyone who wants to be a trader.
    <br/>
    <br/>
    While trading, you will have access to advanced performance analytics in an intuitive user dashboard. Having this information readily available will increase your efficiency and further enhance your trading performance.
    `)
  }
}

export default OurApproachContent;
