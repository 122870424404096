import * as React from "react"
import LayoutOneStepFunding from "../components/layout-one-step-funding"

const IndexPage = () => {
  return (
    <LayoutOneStepFunding landingPage="/" />
  )
}

export default IndexPage
