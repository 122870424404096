import React from "react"

const Callout =() => {
  return (
    <div className="bg-gray-100">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <div className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            The beginning of your trading journey
            <br/>
            <span className="text-indigo-600">With Forex Analytix</span>
          </div>
          <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex shadow">
                <a href={'/#account-types'}
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent face-blue-bg face-blue-btn-hover">
                  Get started
                </a>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Callout
