exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-australia-sydney-js": () => import("./../../../src/pages/australia-sydney.js" /* webpackChunkName: "component---src-pages-australia-sydney-js" */),
  "component---src-pages-canada-toronto-js": () => import("./../../../src/pages/canada-toronto.js" /* webpackChunkName: "component---src-pages-canada-toronto-js" */),
  "component---src-pages-client-terms-js": () => import("./../../../src/pages/client-terms.js" /* webpackChunkName: "component---src-pages-client-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-india-mumbai-js": () => import("./../../../src/pages/india-mumbai.js" /* webpackChunkName: "component---src-pages-india-mumbai-js" */),
  "component---src-pages-instant-funding-js": () => import("./../../../src/pages/instant-funding.js" /* webpackChunkName: "component---src-pages-instant-funding-js" */),
  "component---src-pages-one-step-funding-js": () => import("./../../../src/pages/one-step-funding.js" /* webpackChunkName: "component---src-pages-one-step-funding-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-uk-london-js": () => import("./../../../src/pages/uk-london.js" /* webpackChunkName: "component---src-pages-uk-london-js" */),
  "component---src-pages-usa-new-york-js": () => import("./../../../src/pages/usa-new-york.js" /* webpackChunkName: "component---src-pages-usa-new-york-js" */)
}

