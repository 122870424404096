import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import logo from "../images/logo-header-black.svg"
import { Helmet } from "react-helmet"
import Menu from "./menu"
import img from '../images/instant-funding.jpg'

export default function Header() {
  return (
    <div className="relative bg-black">

      &#160;
      <main className="mx-auto max-w-7xl px-4 sm:px-6 mx-auto max-w-7xl px-4 mt-8 sm:mt-8 sm:px-6 lg:mt-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="text-green mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
                <span className="">Start Trading</span><br />
                <span className="">Your Live Account</span><br />
                <span className="">Immediately!</span>
                <br/>
                <br/>

              </span>
            </h1>
            <div className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-xl xl:text-xl text-white">
              Receive up to 90% of the gains, we cover your losses.
              <br />
            </div>
            <div className="mt-3 text-base text-green sm:mt-5 sm:text-xl lg:text-lg xl:text-lg text-white">
              * Immediate Live Trading Accounts NOT available to US residents
              <br />
            </div>

            <div className="mt-10 mb-10 sm:flex sm:justify-center lg:justify-start">
              <div className="shadow">
                <a
                  href="/#account-types"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover md:py-4 md:text-lg md:px-10"
                >
                  Start Now
                </a>
              </div>
            </div>

          </div>
          <div className="face-black pb-20 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full shadow-lg lg:max-w-xl">

                <span className="sr-only">Watch our video to learn more</span>
                <div className="relative text-center aspect-w-16 aspect-h-9">
                  <div>
                    <img
                      className="inset-0 w-full h-full"
                      src={img}
                      alt=""
                    />
                  </div>
                </div>

            </div>
          </div>
        </div>
      </main>

    </div>
  )
}
