import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import logo from "../images/logo-header-black.svg"
import { Helmet } from "react-helmet"

const navigation = [
  { name: 'Plans', href: '#pricing-plans' },
  { name: 'Immediate Live Account', href: '/instant-funding' },
  { name: '1-Step Assessment', href: '/one-step-funding' },
  { name: 'Forex Analytix', href: 'https://www.forexanalytix.com/', target: '_blank' },
  { name: 'Contact Us', href: 'mailto:info@forexanalytix.com' },
  // { name: 'Our Team', href: '/our-team' },
]
const setMenuItemClass = (currentPath, item) => {
  if (currentPath === '/one-step-funding/' && item.name === '1-Step Assessment')
    return "text-base font-bold text-gray-900 face-blue-hover underline";
  else if (currentPath === '/instant-funding/' && item.name === 'Instant Funding')
    return "text-base font-bold text-gray-900 face-blue-hover underline";
  else
    return "text-base font-bold text-gray-900 face-blue-hover";
}

export default function Menu() {
  let currentPath;
  if (typeof window !== 'undefined') {
    currentPath = window.location.pathname;
  }

  return ( <div className="sticky top-0 z-50">

    <Helmet title="Funded Trading Program"/>
  <Popover className="relative bg-white shadow">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href="/#">
              <span className="sr-only">Funded Trading Program</span>
            <img
              className="h-8 w-auto sm:h-10"
              src={logo}
              alt="Header Logo"
            />
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-10">

          {navigation.map((item) => (

            <a key={item.name} href={item.href} target={item.target} className={setMenuItemClass(currentPath,item)}>
              {item.name}
            </a>
          ))}

        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <a
              href="#pricing-plans"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-bold text-white face-blue-bg face-blue-btn-hover"
          >
            Start Now
          </a>
          <a
            href="https://traderfundingdashboard.propaccount.com/"
            className="ml-2 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-bold text-white bg-gray-800 hover:bg-black"
          >
            Sign In
          </a>
        </div>
      </div>
    </div>

    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
      >
        <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="FACE Logo"
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-base font-bold text-gray-900 face-blue-hover">
                  {item.name}
                </a>
              ))}
            </div>
            <div>
              <a
                  href="/#account-types"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Start Now
              </a>
            </div>
            <div>
              <a
                href="https://traderfundingdashboard.propaccount.com/"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-gray-800 hover:bg-black"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>

  </div>)
}
