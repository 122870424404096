import * as React from "react"
import LayoutInstantFunding from "../components/layout-instant-funding"

const IndexPage = () => {
  return (
    <LayoutInstantFunding landingPage="/" />
  )
}

export default IndexPage
